<template>
    <div class="tab-links">
        <ul class="list-content" v-if="list.length > 0">
            <li v-for="item, index in list" :itemId="item.id" :key="`item-${index}`">
                <a target="_blank" @click="openUrl(item)" class="link-doc" :class="{ 'clicked': item.isClicked }">
                    <div>
                        <pdfFile v-if="item.extension == 'pdf'" />
                        <wordFile v-else-if="['doc', 'docm', 'docx', 'dot', 'dotx'].includes(item.extension)" />
                        <mp3File v-else-if="['mp3', 'm4a'].includes(item.extension)" />
                        <xmlFile
                            v-else-if="['xla', 'xlm', 'xls', 'xlsm', 'xlsx', 'xlt', 'xltm', 'xltx'].includes(item.extension)" />
                        <textFile v-else-if="item.extension == 'txt'" />
                        <mp4File v-else-if="['mp4', 'mpeg', 'wmv'].includes(item.extension)" />
                        <imgFile v-else-if="['png', 'jpeg', 'jpg'].includes(item.extension) || item.type == 'base64'" />
                        <zipFile v-else-if="['zip', 'rar'].includes(item.extension)" />
                        <webFile v-else />
                    </div>

                    <span :id="`tooltip-link-${index}`">{{ item.name }}.{{ item.extension }}</span>

                    <b-tooltip :target="`tooltip-link-${index}`" triggers="hover blur" positioning="top" placement="top" custom-class="tooltip-link"
                        boundary-padding="0">
                        <div class="info-tooltip-link">
                            {{ item.name }}.{{ item.extension }}
                        </div>
                    </b-tooltip>
                </a>
            </li>
        </ul>

        <NotFound v-else />
    </div>
</template>

<script>
import { BTooltip } from 'bootstrap-vue'
import { mapMutations } from 'vuex';
import pdfFile from "@/assets/images/pages/pdf-file.svg"
import webFile from "@/assets/images/pages/web-file.svg"
import wordFile from "@/assets/images/pages/word-file.svg"
import mp3File from "@/assets/images/pages/mp3-file.svg"
import xmlFile from "@/assets/images/pages/xml-file.svg"
import textFile from "@/assets/images/pages/text-file.svg"
import mp4File from "@/assets/images/pages/mp4-file.svg"
import imgFile from "@/assets/images/pages/img-file.svg"
import zipFile from "@/assets/images/pages/zip-file.svg"

export default {
    components: {
        BTooltip,
        NotFound: () => import('../NotFound.vue'),
        pdfFile,
        webFile,
        wordFile,
        mp3File,
        xmlFile,
        textFile,
        mp4File,
        imgFile,
        zipFile,
    },
    props: {
        list: {
            type: Array,
            default: () => {
                return []
            }
        },
    },
    mounted() {
        this.UPDATE_FLAG_SHOW_OVERLAY(false);
    },
    methods: {
        ...mapMutations('app', [
            'UPDATE_FLAG_SHOW_OVERLAY'
        ]),
        openUrl(item) {
            this.$set(item, 'isClicked', true);

            if (item.type == 'base64') {
                var win = window.open();
                win.document.write('<iframe src="' + item.url + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
            }
            else {
                window.open(item.url, '_blank')
            }
        },
    }
}
</script>

<style lang="scss">
.tooltip-link{
    z-index: 99999999;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}
</style>

<style lang="scss" scoped>
.tab-links {
    flex-grow: 1;

    li {
        height: 84px;
        display: flex;
        align-items: center;
        padding: 0 29px;
        border-bottom: 1px solid #CFC4BE;
        user-select: none;
        cursor: pointer;

        .link-doc {
            display: flex;
            align-items: center;
            gap: 20px;
            max-width: 100%;

            &.clicked {
                span {
                    color: #998F8A !important;
                }
            }

            span {
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
                color: #974900;
                word-break: break-all;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .list-content {
        &::-webkit-scrollbar {
            width: 16px;
        }

        &::-webkit-scrollbar-track {
            background: #fff;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #CFC4BE;
            border-radius: 20px;
            border: 4px solid #fff;
            height: 70px;
        }

        overflow: auto;
        padding: 0;
        margin: 0;
    }
}

@media (max-width: 768px) {
    .tab-links {
        li {
            .link-doc {
                span {
                    font-size: 22px;
                }
            }
        }
    }
}

@media (max-width: 425px) {
    .tab-links {
        li {
            .link-doc {
                span {
                    font-size: 14px;
                }
            }
        }
    }
}
</style>
